import React from "react";
import { Link } from "gatsby";
import posthog from "posthog-js";

import apptBackgroundNarrow from "../assets/nicole-honeywill-1432804-unsplash-narrow.jpg";
import apptBackgroundWide from "../assets/nicole-honeywill-1432804-unsplash-wide.jpg";
import opticalBackgroundNarrow from "../assets/office-inside-narrow.jpg";
import opticalBackgroundWide from "../assets/office-inside-wide.jpg";
import insuranceBackgroundNarrow from "../assets/office-outside-narrow.jpg";
import insuranceBackgroundWide from "../assets/office-outside-wide.jpg";

import { LightboxButton } from "../components/appt-lightbox";
import Layout from "../components/layout";

export default function Home() {
  posthog.capture("$home");
  return (
    <Layout>
      <main id="content" role="main" className="main sv-landing">
        {/* <section className="sv-hero-content-wrapper center overflow-hidden">
          <div id="header-announcement">
            <p>
              We are having technical issues with our appointment system not
              sending email notifications. For last minute updates to your
              appointment, please give us a call. Thank you!
            </p>
          </div>
        </section> */}
        <section className="sv-hero-content-wrapper center overflow-hidden">
          <picture>
            <source
              media="(max-width: 52.06rem)"
              srcSet={apptBackgroundNarrow}
            />
            <source media="(min-width: 52.07rem)" srcSet={apptBackgroundWide} />
            <img
              src={apptBackgroundWide}
              className="sv-hero-image"
              alt="Eye Care"
            />
          </picture>
          <div className="sv-hero-content sv-hero-content-theme-secondary flex justify-center flex-column md-mx-auto md-p0">
            <div className="sv-hero-align md-mx-auto">
              <div className="sv-hero-content-title-body-wrapper md-pt3 md-ml4">
                <h2 className="sv-hero-content-title h4 md-h1 caps md-mb2 md-pl4">
                  Eye Care
                </h2>
                <p className="sv-hero-content-body">
                  Comprehensive Eye Exams, Contact Lens Fittings, Emergency Eye
                  Care, LASIK co-management
                </p>
              </div>
              <LightboxButton />
            </div>
          </div>
        </section>

        <section className="sv-hero-content-wrapper center overflow-hidden">
          <picture>
            <source
              media="(max-width: 52.06rem)"
              srcSet={opticalBackgroundNarrow}
            />
            <source
              media="(min-width: 52.07rem)"
              srcSet={opticalBackgroundWide}
            />
            <img
              src={opticalBackgroundWide}
              className="sv-hero-image"
              alt="Optical Shop"
            />
          </picture>
          <div className="sv-hero-content sv-hero-content-theme-secondary flex justify-center flex-column md-mx-auto md-p0">
            <div className="sv-hero-align md-mx-auto">
              <div className="sv-hero-content-title-body-wrapper md-pt3 md-ml4">
                <h2 className="sv-hero-content-title h4 md-h1 caps md-mb2 md-pl4">
                  Optical
                </h2>
                <p className="sv-hero-content-body">
                  We carry a wide selection of ophthalmic glasses, sunglasses,
                  and lenses.
                </p>
              </div>
              <div className="sv-btn sv-btn-secondary caps md-ml4">
                <Link to="/brands" className="text-decoration-none">
                  see brands
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="sv-hero-content-wrapper center overflow-hidden">
          <picture>
            <source
              media="(max-width: 52.06rem)"
              srcSet={insuranceBackgroundNarrow}
            />
            <source
              media="(min-width: 52.07rem)"
              srcSet={insuranceBackgroundWide}
            />
            <img
              src={insuranceBackgroundWide}
              className="sv-hero-image"
              alt="Insurance"
            />
          </picture>
          <div className="sv-hero-content sv-hero-content-theme-secondary flex justify-center flex-column md-mx-auto md-p0">
            <div className="sv-hero-align md-mx-auto">
              <div className="sv-hero-content-title-body-wrapper md-pt3 md-ml4">
                <h2 className="sv-hero-content-title h4 md-h1 caps md-mb2 md-pl4">
                  Insurance
                </h2>
                <p className="sv-hero-content-body">
                  We can help you make the most of your insurance benefits.
                </p>
              </div>
              <div className="sv-btn sv-btn-secondary caps md-ml4">
                <Link to="/insurance" className="text-decoration-none">
                  see insurance plans
                </Link>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="sv-hero-content-wrapper center overflow-hidden">
          <amp-iframe width="200" height="75"
            sandbox="allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
            layout="responsive"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3155.798352914243!2d-122.15772728426202!3d37.724410779768675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808f8f96854f72cd%3A0x548c8e241ad72426!2sSimply+Vision+Optometry!5e0!3m2!1sen!2sus!4v1543652862405">
          </amp-iframe>
        </section> */}
      </main>
    </Layout>
  );
}
